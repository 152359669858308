import '../scss/main.scss';

import 'bootstrap';

import { CountUp } from 'countup.js';
import Swiper from 'swiper';
import { Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/pagination';
import SimpleParallax from 'simple-parallax-js';

document.addEventListener('DOMContentLoaded', () => {
	Swiper.use([Autoplay, FreeMode]);

	// Count ups
	const countUps = document.querySelectorAll('.count-up');
	countUps.forEach((el) => {
		new CountUp(el, el.dataset.number, { enableScrollSpy: true });
	});

	// Scrollers
	const Scrollers = document.querySelectorAll('.scroll-down');
	Scrollers.forEach((el) => {
		el.addEventListener('click', () => {
			window.scroll(0, 820);
		});
	});

	// Initialize parallax
	const image = document.getElementsByClassName('parallax');
	new SimpleParallax(image);

	// Initialize clients widget's sliders
	if (document.querySelector('.swiper-clients')) {
		const slides = document.querySelectorAll('.swiper-clients .swiper-slide').length;
		new Swiper('.swiper-clients', {
			loop: true,
			speed: 5000,
			freeMode: true,
			slidesPerView: 2,
			autoplay: {
				delay: 1,
			},
			breakpoints: {
				750: {
					slidesPerView: Math.min(slides, 3),
					spaceBetween: 30,
				},
				1080: {
					slidesPerView: Math.min(slides, 4),
					spaceBetween: 50,
				},
				1550: {
					slidesPerView: Math.min(slides, 5),
					spaceBetween: 90,
				},
			},
		});
	}

	// Initialize icon banner widget's sliders
	if (document.querySelector('.swiper-icon-banner')) {
		const slides = document.querySelectorAll('.swiper-icon-banner .swiper-slide').length;
		new Swiper('.swiper-icon-banner', {
			loop: true,
			autoplay: {
				delay: 1,
				disableOnInteraction: false
			},
			slidesPerView: 3,
			allowTouchMove: false,
			breakpoints: {
				750: {
					slidesPerView: Math.min(slides, 4),
				},
				1080: {
					slidesPerView: Math.min(slides, 5),
					spaceBetween: 50,
				},
				1550: {
					slidesPerView: Math.min(slides, 6),
					spaceBetween: 90,
				},
			},
			speed: 10000,
		});
	}

	$('#ajax-contact-form').submit(function submitContactForm(e) {
		e.preventDefault();

		const formData = new FormData($(this)[0]);

		formData.append('last_name', $('#last_name').val());
		formData.append('first_name', $('#first_name').val());
		formData.append('email', $('#email').val());
		formData.append('phone', $('#phone').val());
		formData.append('first_name', $('#first_name').val());
		formData.append('message', $('#message').val());
		formData.append('type', $('button[aria-selected="true"]').data('type'));

		const url = $(this).attr('action');

		$.ajax({
			url,
			type: 'post',
			data: formData,
			contentType: false,
			processData: false,
			success() {
				$('.form-success').show();
				$(':input', '#ajax-contact-form')
					.not(':button, :submit, :reset, :hidden')
					.val('')
					.prop('checked', false)
					.prop('selected', false);
			}
		});
	});
});

